import React, { useState } from 'react';
import { Grid, Typography, Card, CardContent, TextField, Button } from '@mui/material';
import styled from 'styled-components';

// Styled Button with Anton font
const StyledButton = styled(Button)`
  background-color: #83d6f7 !important;
  color: #121212 !important;
  font-family: 'Anton', sans-serif !important;
  padding: 12px 30px !important;
  border-radius: 50px !important;
  font-size: 20px !important;
  margin-top: 20px !important;
  text-transform: uppercase;
  &:hover {
    background-color: #64d9fb !important;
  }
`;

// Styled Typography for explanation and results
const StyledTypography = styled(Typography)`
  font-family: 'Anton', sans-serif !important;
  color: #fff;
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: center;
`;

// Result Text Styling
const ResultText = styled(Typography)`
  font-family: 'Anton', sans-serif !important;
  color: #83d6f7;
  font-size: 1.5rem;
  text-align: center;
  margin-top: 20px;
`;

// Title with h1 element and adjusted font size
const StyledTitle = styled(Typography).attrs({ variant: 'h1' })`
  font-family: 'Anton', sans-serif !important;
  font-size: 4rem !important; /* Adjusted title size */
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 1rem; /* Adjust for smaller screens */
  }
`;

const AllocationCalculator = () => {
  const [mnk3ys, setMnk3ys] = useState('');
  const [blunana, setBlunana] = useState('');
  const [allocation, setAllocation] = useState(null);

  const calculateAllocation = () => {
    const mnk3ysCount = parseInt(mnk3ys) || 0;  // Default to 0 if the input is empty or invalid
    const blunanaCount = parseInt(blunana) || 0;  // Default to 0 if the input is empty or invalid
    const totalNfts = mnk3ysCount + blunanaCount;

    if (totalNfts <= 0) {
      setAllocation(0);
      return;
    }

    // Calculate the number of complete sets of 10 NFTs
    const fullSetsOfTen = Math.floor(totalNfts / 10);
    const remainder = totalNfts % 10;

    // Each set of 10 NFTs gets 10x the allocation
    const bonusAllocation = fullSetsOfTen * 100000; // 100,000 for each 10 NFTs
    const regularAllocation = remainder * 1000; // 1,000 per NFT for the remainder

    const totalAllocation = bonusAllocation + regularAllocation;

    setAllocation(totalAllocation);
  };

  return (
    <Card sx={{ padding: '20px', boxShadow: 'none', borderRadius: '12px', backgroundColor: 'transparent' }}>
      <CardContent>
        <Grid container spacing={3} justifyContent="center">
          {/* Heading */}
          <Grid item xs={12}>
            <StyledTitle>
              $BLUNANA Allocation Calculator
            </StyledTitle>
            <StyledTypography>
              Use this tool to calculate your total $BLUNANA allocation based on the number of MNK3Ys and Blunana NFTs you own.
              For every NFT, you receive 1000 $BLUNANA. However, once you own 10 NFTs, your allocation increases 10x, meaning you'll
              receive 100,000 $BLUNANA instead of the usual 10,000. Enter your MNK3Ys and Blunana NFTs to see your reward.
            </StyledTypography>
          </Grid>

          {/* Input for MNK3Ys */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="MNK3Ys Owned"
              type="number"
              value={mnk3ys}
              onChange={(e) => setMnk3ys(e.target.value)}
              variant="outlined"
              InputLabelProps={{ style: { fontFamily: 'Anton', color: '#83d6f7' } }}
              sx={{
                input: { color: '#fff', fontFamily: 'Anton' },
                '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: '#83d6f7' } },
              }}
            />
          </Grid>

          {/* Input for Blunana */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Blunana NFTs Owned"
              type="number"
              value={blunana}
              onChange={(e) => setBlunana(e.target.value)}
              variant="outlined"
              InputLabelProps={{ style: { fontFamily: 'Anton', color: '#83d6f7' } }}
              sx={{
                input: { color: '#fff', fontFamily: 'Anton' },
                '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: '#83d6f7' } },
              }}
            />
          </Grid>

          {/* Calculate Button */}
          <Grid item xs={12} textAlign="center">
            <StyledButton onClick={calculateAllocation}>
              Calculate Allocation
            </StyledButton>
          </Grid>

          {/* Result Display */}
          {allocation !== null && (
            <Grid item xs={12}>
              <ResultText>
                Your total $BLUNANA allocation is: {allocation.toLocaleString()} $BLUNANA
              </ResultText>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AllocationCalculator;

